import React from "react";
import { SVG } from "../../assets/images";
import ExternalLink from "../ExternalLink/ExternalLink";
import PerformanceActions from "../PerformanceActions/PerformanceActions";
import "./ShortFormPerformanceInfo.scss";

const LinkItem: React.FunctionComponent<any> = ({ url, text, image, alt, target, children, style}) => {
  return <ExternalLink url={url} text={text} image={image} alt={alt}  style={style}  target={target} children={children} />
};

class ShortFormPerformanceInfo extends React.Component<any,any> {
  render() {
    const {
      header,
      briefSynopsis,
      ticketURL,
    } = this.props.info;

    return (
      <div className="ShortFormPerformanceInfo">
        <div className="performance-info">
          { header && <h1 className="performance-title" dangerouslySetInnerHTML={{__html: header}} /> }
          { briefSynopsis && <p className="performance-description"  dangerouslySetInnerHTML={{__html: briefSynopsis}} /> }
        </div>
        <div className="performance-ctas">
          <PerformanceActions  />
          <LinkItem url={ticketURL || "https://www.metopera.org/calendar"} style={{textDecoration:"none"}}>
            <button className="btn btn-transparent white" style={{cursor: "pointer"}}><span>Buy Tickets<SVG.Calendar/></span></button>
          </LinkItem>
        </div>
      </div>
    )
  }
}

export default ShortFormPerformanceInfo;
