import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { withStore } from "../../store";
import brightcovePlayerLoader from "@brightcove/player-loader";
import { Button } from "@brightcove/studio-components";
import { SVGImage, SVG } from "../../assets/images";
import Grid from "../../components/Grid/Grid";
import { fetchHandler, getClientApiURL, isShortFormContent } from "../../utils";
import "./Player.scss";

let authzId;

const checkAuthorization = (
  onSuccess,
  onFailure,
  sessionId?,
  reference_id?
) => {
  let authzURL = "/authorization";
  if (sessionId) {
    authzURL = `${authzURL}/${sessionId}`;
  }
  let url = `${getClientApiURL(authzURL)}`;

  if (reference_id) {
    const newURL = new URL(url);
    newURL.searchParams.set("reference_id", reference_id);
    url = newURL.toString();
  }
  const options = {
    method: "GET",
  };
  fetchHandler(url, options, onSuccess, onFailure);
};

const checkEntitlement = (onSuccess, onFailure, extId) => {
  const url = `${getClientApiURL(`/authorization?reference_id=${extId}`)}`;
  const options = {
    method: "GET",
  };
  fetchHandler(url, options, onSuccess, onFailure);
};

class Player extends React.Component<any, any> {
  player;
  CONTINUITY_FREQUENCY = 10 * 1000;
  constructor(props) {
    super(props);
    this.state = {
      entitled: undefined,
      paused: true,
    };
  }
  componentDidMount() {
    const { user } = this.props.store;
    const isStudent = user && user.data && user.institutionName;
    if (!this.props.store.userFetchPending) {
      const $this = this;
      if (isStudent) {
        checkAuthorization(
          ({ session, status }) => {
            if (status && status === 429) {
              $this.props.history.replace("/user_limit");
            } else {
              authzId = session;
              $this.brightcovePlayer();
              setInterval(() => {
                checkAuthorization(
                  undefined,
                  undefined,
                  authzId,
                  this.props.info.extId
                );
              }, $this.CONTINUITY_FREQUENCY);
            }
          },
          ({ status }) => {
            console.log("unhandled error in checkAuthz fetch");
          },
          authzId,
          this.props.info.extId
        );
      } else {
        checkEntitlement(
          () => this.setState({ entitled: true }),
          () => {
            this.setState({ entitled: false });
          },
          this.props.info.extId
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(
        prevProps.store.userFetchPending,
        this.props.store.userFetchPending
      ) ||
      prevProps.info.id !== this.props.info.id ||
      !_.isEqual(prevState.entitled, this.state.entitled)
    ) {
      if (this.player) {
        this.player.dispose();
      }
      this.brightcovePlayer();
    }
  }

  brightcovePlayer = () => {
    return loadBrightcovePlayer.call(this, this.props, this.state);
  };

  disposePlayer = () => {
    if (this.player) {
      const playerId = isShortFormContent(this.props.info.mediaType)
        ? process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_SHORT_FORM
        : process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_LONG_FORM;
      const playerStylesContainer = document.querySelector(
        `.bc-style-${playerId}-default`
      );

      brightcovePlayerLoader.reset();
      this.player.dispose();

      if (playerStylesContainer) {
        playerStylesContainer.remove();
      }
    }
  };

  togglePlay = () => {
    if (this.player) {
      const isPaused = this.player.paused();

      if (isPaused) {
        this.player.play();
      } else {
        this.player.pause();
      }

      this.setState({
        paused: !isPaused,
      });
    }
  };

  onVideoEnded = () => {
    this.setState({
      paused: true,
    });
  };

  componentWillUnmount() {
    this.disposePlayer();
  }

  // Add to MOoD Cart
  /*

    public enum SubscriptionTypeEnum

    {

        None = 0,

        Annual = 1,

        Monthly = 2,

        SixMonth = 3,

        Trial = 4,

        RentalAudio = 5,

        RentalVideo = 6,

        RentalHDVideo = 7,

        Comp = 9,

        Employee = 10,

        AnnualGift = 14,

        SpecialOffer = 100,

        YearlyMember = 35,

        RokuTrial = 47,

        RokuMonthly = 48,

        RokuAnnual = 49,

        SamsungTrial = 50,

        SamsungMonthly = 51,

        SamsungAnnual = 52,

        iOSInAppTrial = 58,

        iOSInAppMonthly = 59,

        iOSInAppAnnual = 60,

        RokuInAppTrial = 61,

        RokuInAppMonthly = 62,

        RokuInAppAnnual = 63,

        AmazonInAppTrial = 65,

        AmazonInAppMonthly = 66,

        AmazonInAppAnnual = 67,

        MetOperaOnDemand3MonthPromo = 69

    }



*/
  // https://metopera.org/MoodSubscribe/MoodSubscribe?subType=7&offerId=0&returnUrl=%2Fseason%2Fon-demand%2Fopera%2F%3Fupc%3D810004202993&videoUPC=810004202993&emptyCartIfTrue=False

  // much of the markup that is added here gets moved around/manipulated by the plugin itself. I don't believe this to be a sustainiable way of managing the player overlays. It should be revisisted in the future

  addRentalToCart = () => {
    const mediaType = this.props.performance.mediaType;
    const resolutionType = this.props.performance.resolutionType;
    const subType =
      mediaType === "VIDEO" ? (resolutionType === "HD" ? 7 : 6) : 5;
    window.location = `https://metopera.org/MoodSubscribe/MoodSubscribe?subType=${subType}&offerId=0&returnUrl=${encodeURIComponent(
      window.location
    )}&videoUPC=${this.props.performance.extId}&emptyCartIfTrue=False`;
  };

  addToCart = (purchaseType) => {
    const mediaType = this.props.performance.mediaType;
    const resolutionType = this.props.performance.resolutionType;
    let subType;
    if (purchaseType === "rental") {
      subType = mediaType === "VIDEO" ? (resolutionType === "HD" ? 7 : 6) : 5;
    } else {
      subType = 4;
    }
    if (purchaseType === "trial") {
      window.location = "http://metopera.org/season/on-demand/learn-more";
    } else {
      window.location = `https://metopera.org/MoodSubscribe/MoodSubscribe?subType=${subType}&offerId=0&returnUrl=${encodeURIComponent(
        window.location
      )}&videoUPC=${this.props.performance.extId}&emptyCartIfTrue=False`;
    }
  };

  render() {
    const {
      showLogIn,
      info,
      performance,
      endScreenInfo,
      history,
      store: { user },
    } = this.props;
    const { imageurl, header, mediaType } = info;
    const { entitled, paused } = this.state;
    const isStudent = user && user.data && user.institutionName;

    return (
      <div
        className={
          mediaType === "short_form_audio" ? `Player-wrapper ${mediaType}` : ""
        }
      >
        {mediaType === "short_form_audio" && (
          <div className="back-btn" onClick={() => history.push("/")}>
            <SVG.BackArrow />
            <span>Back</span>
          </div>
        )}
        {mediaType === "short_form_audio" && (
          <div className="performance-info-wrapper">
            <div className="performance-image">
              <img src={imageurl} alt="Performance" />
            </div>
            <div className="performance-info">
              {header && (
                <h2
                  className="performance-title"
                  dangerouslySetInnerHTML={{ __html: header }}
                />
              )}
              <div className="Player-controls">
                <div className="play-pause-btn" onClick={this.togglePlay}>
                  <img
                    src={paused ? SVGImage.PlayBtn : SVGImage.PauseBtn}
                    alt={paused ? "Play Button" : "Pause Button"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="Player">
          {mediaType !== "short_form_audio" && (
            <div className="video-hover hidden-mobile">
              {mediaType !== "short_form_video" && (
                <NavLink to="/" exact className="video-hover-logo">
                  <SVG.MetLogo />
                </NavLink>
              )}
              <div
                className="video-hover-catalog back-btn"
                onClick={() => history.push("/")}
              >
                <SVG.BackArrow />
                <span>Back</span>
              </div>
            </div>
          )}
          {!isShortFormContent(mediaType) && (
            <div
              className={`video-end player-overlay-end-screen hidden-mobile ${
                user && entitled ? "logged-in" : "logged-out"
              } `}
            >
              <div className="video-end-overlay"></div>
              {/* <Button theme="classic" Component="a" componentProps={{href: "https://www.metopera.org/calendar", target: "_blank"}} className="btn btn-transparent white buy-ticket-cta">Buy Tickets<SVG.Calendar /></Button> */}
              {!isStudent && (!user || (user && !entitled)) && (
                <div className="video-end-details">
                  <p className="player-overlay-title hidden-mobile">
                    <i>{info.header}</i> is only available with a<br />
                    Met Opera on Demand subscription or rental.
                  </p>
                  <Button
                    theme="classic"
                    className="btn btn-pink"
                    onClick={this.addToCart.bind(this, "trial")}
                  >
                    Start free 7-day trial
                  </Button>
                  <Button
                    theme="classic"
                    className="btn btn-transparent white"
                    onClick={this.addRentalToCart}
                  >
                    Rent for ${performance.rentalPrice}
                  </Button>
                  <p>
                    Already a Met Opera on Demand subscriber?{" "}
                    <span className="video-end-ask" onClick={showLogIn}>
                      Login
                    </span>
                  </p>
                  <Button
                    theme="classic"
                    Component="a"
                    componentProps={{
                      href: "https://www.metopera.org/season/on-demand/Learn-More/",
                      target: "_blank",
                    }}
                    className="btn btn-transparent white btn-learn-more"
                  >
                    Learn more<span> about on demand</span>
                  </Button>
                </div>
              )}
              {user && entitled && endScreenInfo.tiles.length > 0 && (
                <Grid {...endScreenInfo} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function loadBrightcovePlayer(this: Player, props, state) {
  const {
    info: { id, extId, mediaType, position, tier },
    store: { user },
  } = this.props;
  const accountId = isShortFormContent(mediaType)
    ? process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_SHORT_FORM
    : process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID_LONG_FORM;
  const playerId = isShortFormContent(mediaType)
    ? process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_SHORT_FORM
    : process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID_LONG_FORM;
  const playerSettings = {
    refNode: ".Player",
    refNodeInsert: "append",
    accountId: accountId,
    playerId: playerId,
    embedId: "default",
    options: {
      id: "player",
    },
    onEmbedCreated: function (embed) {
      embed.removeAttribute("controls");
    },
  };

  const isStudent = user && user.data && user.institutionName;

  if (isShortFormContent(mediaType)) {
    playerSettings["videoId"] = `${extId}`;
  }

  return brightcovePlayerLoader(playerSettings)
    .then((success) => {
      let continuityInterval;
      this.player = success.ref;

      if (!isShortFormContent(mediaType)) {
        this.player.MOoD({
          video: `ref:${extId}`,
          percentWatched: position,
          user: isStudent || (state.entitled && user) ? user : null,
          isStudent,
          entitled: state.entitled,
          tier,
        });

        const updateContinuity = () => {
          const time = this.player.currentTime(),
            complete = time / this.player.duration() > 0.98; // within 2% of end, call it complete

          if (!complete) {
            const url = `${getClientApiURL("/event")}`;
            const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                eventType: "update-video-position",
                data: {
                  contentId: id,
                  position: Math.floor(time * 1000),
                },
              }),
            };
            fetchHandler(url, options);
          } else {
            onVideoEnded();
          }
        };

        const deleteContinuity = () => {
          const url = `${getClientApiURL("/event")}`;
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              eventType: "delete-video-position",
              data: {
                contentId: id,
              },
            }),
          };
          fetchHandler(url, options);
        };

        const clearContinuityInterval = () => {
          continuityInterval && clearInterval(continuityInterval);
          continuityInterval = null;
        };

        const onVideoEnded = () => {
          deleteContinuity();
          clearContinuityInterval();
        };

        if (user && state.entitled) {
          this.player.one("canplay", () => {
            if (position) {
              this.player.currentTime(position / 1000);
            }
          });

          this.player.on("play", () => {
            continuityInterval = setInterval(function () {
              updateContinuity();
            }, this.CONTINUITY_FREQUENCY);
          });

          this.player.on("pause", () => {
            clearContinuityInterval();
            updateContinuity();
          });

          this.player.on("ended", onVideoEnded);

          this.player.on("dispose", function () {
            clearContinuityInterval();
          });
        }
      } else {
        this.player.on("ended", this.onVideoEnded);
      }
    })
    .catch((err) => {
      this.setState({
        error: true,
        errorMsg: "Player failed to load.",
      });
      console.error(err);
    });
}

export default compose(withRouter, withStore)(Player);
