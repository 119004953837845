import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { withStore } from "../../store";
import ExternalLink from "../ExternalLink/ExternalLink";
import classnames from "classnames";
import { SVG, SVGImage } from "../../assets/images";
import { fetchHandler, getClientApiURL } from "../../utils";
import "./Header.scss";

const LinkItem: React.FunctionComponent<any> = ({ url, text, image, alt, target, style }) => {
  return <li><ExternalLink url={url} text={text} image={image} style={style} alt={alt} target={target} /></li>
};

class Header extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    }
  }

  toggleAccountMenu = () => {
    // @ts-ignore
    document.querySelector(".Header-menu-secondary .account-menu").classList.toggle("open");
  }

  toggleSubMenu = (id) => {
    // @ts-ignore
    document.querySelectorAll(".Header-nav-menu-item-subnav").forEach((el) => {
      if (el.getAttribute("id") !== id) {
        el.classList.add("hide");
      } else {
        el.classList.toggle("hide");
      }
    });
  }

  showMenu = () => {
    // @ts-ignore
    document.querySelector("body").classList.add("header-nav-open");
    this.setState({menuOpen : true});
  }

  hideMenu = () => {
    // @ts-ignore
    document.querySelector("body").classList.remove("header-nav-open");
    this.setState({menuOpen : false});
  }

  showLogIn = () => {
    this.hideMenu();
    this.props.showLogIn();
  }

  scrollToTop = () => {
    this.hideMenu();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onLogOut = () => {
    this.hideMenu();
    const logoutUrl = getClientApiURL("/logout");
    fetchHandler(logoutUrl, {}, this.props.handleLogout);
  }

  onAccountMenuMouseEnter = () => {
    // @ts-ignore
    document.querySelector(".Header-menu-secondary .account-menu").classList.add("hover");
  }

  onAccountMenuMouseLeave = () => {
    // @ts-ignore
    document.querySelector(".Header-menu-secondary .account-menu").classList.remove("hover");
  }

  render() {
    const { menuOpen } = this.state;
    const { showLogIn, store: { displayLogIn, inMaintenance, user, userFetchPending } } = this.props;
    return (
      <div className={classnames("Header", { "menu-open": menuOpen }, { "login-page-header": displayLogIn })}>
        <div className="Header-nav">
          {!inMaintenance &&
            <React.Fragment>
              <div className="Header-menu-nav-trigger">
                <div className="Header-nav-menu-toggle" onClick={this.showMenu}><img src={SVGImage.Menu} alt="Nav Toggle" />Menu</div>
                <div className="Header-nav-menu-close" onClick={this.hideMenu}><img src={SVGImage.Close} alt="Close Icon" />Close</div>
              </div>
              <div className="Header-nav-menu-container">
                <ul>
                  <li>
                    <span onClick={() => this.toggleSubMenu("season")}>Season</span>
                    <ul className="Header-nav-menu-item-subnav hide" id="season">
                      <LinkItem url="https://metopera.org/season/2024-25-season/" text="2024-25 Season" />
                      <LinkItem url="https://www.metopera.org/season/2025-26-season/" text="2025-26 Season" />
                      <LinkItem url="https://www.metopera.org/season/in-cinemas/" text="In Cinemas" />
                      <LinkItem url="https://www.metopera.org/season/in-cinemas/the-met-live-at-home/" text="At Home" />
                      <li><NavLink to="/" onClick={this.scrollToTop} exact>On Demand</NavLink></li>
                      <LinkItem url="https://www.metopera.org/season/radio/" text="Radio" />
                      <LinkItem url="https://www.metopera.org/season/television/" text="Television" />
                      <LinkItem url="https://www.metopera.org/season/events/" text="Events" />
                      <LinkItem url="https://www.metopera.org/season/tickets/" text="Tickets" />
                    </ul>
                  </li>
                  <LinkItem url="https://www.metopera.org/season/tickets/" text="Tickets" />
                  <li><NavLink to="/" onClick={this.scrollToTop} exact>On Demand</NavLink></li>
                  <li>
                    <span onClick={() => this.toggleSubMenu("visit")}>Visit</span>
                    <ul className="Header-nav-menu-item-subnav hide" id="visit">
                      <LinkItem url="https://www.metopera.org/information/our-commitment-to-our-audiences/" text="Health and Safety Requirements" />
                      <LinkItem url="https://www.metopera.org/visit/getting-here/" text="Getting Here" />
                      <LinkItem url="https://www.metopera.org/visit/accessibility/" text="Accessibility" />
                      <LinkItem url="https://www.metopera.org/visit/dining-at-the-met/" text="Dining at the Met" />
                      <LinkItem url="https://www.metopera.org/visit/tours/" text="Tours" />
                      <LinkItem url="https://www.metopera.org/visit/what-to-expect/" text="What to Expect" />
                      <LinkItem url="https://www.metopera.org/visit/exhibitions/" text="Exhibitions" />
                    </ul>
                  </li>
                  <li>
                    <span onClick={() => this.toggleSubMenu("discover")}>Discover</span>
                    <ul className="Header-nav-menu-item-subnav hide" id="discover">
                      <LinkItem url="https://www.metopera.org/discover/video/" text="Videos" />
                      <LinkItem url="https://www.metopera.org/discover/talks-and-events/" text="Talks and Events" />
                      <LinkItem url="https://www.metopera.org/discover/articles/" text="Articles" />
                      <LinkItem url="https://www.metopera.org/discover/education/" text="Education" />
                      <LinkItem url="https://www.metopera.org/discover/podcasts/" text="Podcasts" />
                      <LinkItem url="https://www.metopera.org/discover/artists/" text="Artists" />
                      <LinkItem url="https://www.metopera.org/discover/synopses/" text="Synopses" />
                      <LinkItem url="https://www.metopera.org/discover/archives/" text="Archives" />
                    </ul>
                  </li>
                  <li>
                    <span onClick={() => this.toggleSubMenu("make-a-gift")}>Make A Gift</span>
                    <ul className="Header-nav-menu-item-subnav hide" id="make-a-gift">
                      <LinkItem url="https://www.metopera.org/protectthemet" text="Give Now" />
                      <LinkItem url="https://www.metopera.org/support/join-the-met/" text="Become A Member" />
                      <LinkItem url="https://www.metopera.org/support/make-a-gift/" text="Special Funds" />
                      <LinkItem url="https://www.metopera.org/support/corporate-partnership/" text="Corporate Partnership" />
                      <LinkItem url="https://plannedgiving.metopera.org/" text="Leave a Legacy" />
                    </ul>
                  </li>
                  <li>
                    <span onClick={() => this.toggleSubMenu("about")}>About</span>
                    <ul className="Header-nav-menu-item-subnav hide" id="about">
                      <LinkItem url="https://www.metopera.org/about/the-met/" text="Our story" />
                      <LinkItem url="https://www.metopera.org/about/press-releases/" text="Press Releases" />
                      <LinkItem url="https://www.metopera.org/about/who-we-are/" text="Who We Are" />
                      <LinkItem url="https://www.metopera.org/about/faq/" text="FAQ" />
                      <LinkItem url="https://www.metopera.org/about/jobs/" text="Jobs" />
                      <LinkItem url="https://www.metopera.org/about/internships/" text="Internships" />
                      <LinkItem url="https://www.metopera.org/about/auditions/" text="Auditions" />
                      <LinkItem url="https://www.metopera.org/about/contact-us/" text="Contact Us" />
                      <LinkItem url="https://www.metopera.org/about/annual-reports/" text="Annual Reports" />
                      <LinkItem url="https://www.metopera.org/about/brochures/" text="Brochures" />
                    </ul>
                  </li>
                  <LinkItem url="https://www.metoperashop.org/" text="Shop" />
                  <li className="Header-nav-menu-bottom Header-nav-menu-search"><NavLink to="/search" onClick={this.hideMenu} exact><img src={SVGImage.Search} alt="Search Icon" />Search</NavLink></li>
                  {!user && <li className="Header-nav-menu-bottom"><span onClick={this.showLogIn}><img src={SVGImage.Login} alt="Login Icon" />Login</span></li> }
                  {user && user.id && <li className="Header-nav-menu-bottom"><ExternalLink url="https://www.metopera.org/account/my-account-hub/" text="My Account" target="_self" image={SVGImage.Login} /></li>}
                  {user && user.id && <li className="Header-nav-menu-bottom"><span onClick={this.onLogOut}><img src={SVGImage.Login} alt="Login Icon" />Logout</span></li> }
                  <li className="Header-nav-menu-bottom">
                    <ExternalLink url="https://www.metopera.org/account/my-account-hub/#" text="Live Chat" image={SVGImage.Chat}/>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          }
          <div className="Header-menu-home">
            <NavLink to="/" onClick={this.scrollToTop} exact><img src={SVGImage.MetOperaLogo} alt="MetOpera Logo" /></NavLink>
          </div>
          {!inMaintenance &&
            <div className="Header-menu-secondary">
              {!userFetchPending && !user && <span onClick={showLogIn}><img src={SVGImage.Login} alt="Login Icon" />Login / Register</span>}
              {!userFetchPending && user &&
              <div className="account-menu" onMouseEnter={this.onAccountMenuMouseEnter} onMouseLeave={this.onAccountMenuMouseLeave}>
                <span className="account-menu-container">
                {
                  (user.id && <ExternalLink url="https://www.metopera.org/account/my-account-hub/" text="My Account" image={SVGImage.Login} alt="Global Login Icon" target="_self" />)
                  ||
                  <span> <img src={SVGImage.Login} alt="Global Login Icon" />Student Access Account</span>
                }

                  { user.id && <SVG.ArrowDown onClick={this.toggleAccountMenu } className="account-menu-dropdown-arrow" />}
                </span>
                { user.id && ( <ul className="account-menu-dropdown">
                  <li onClick={this.onLogOut}>Logout</li>
                  <LinkItem url="https://www.metopera.org/account/account-settings/?emailpreferences=" text="Email Preferences" target="_self" />
                </ul> ) }
              </div>
              }
              <ExternalLink url="https://www.metopera.org/calendar/" text="Calendar" image={SVGImage.Calendar} alt="Calendar Icon" target="_self" />
            </div>
          }
        </div>
      </div>
    )
  }
};

export default compose(withRouter, withStore)(Header);
